import styled from "styled-components";
import { theme } from "../../theme";

export const Title = styled.div`
  font-size: 30px;
  color: ${theme.colors.accented};
  margin-bottom: 15px;
`

export const TitleCheckedContainer = styled.div`
  position: relative;
  padding-left: 50px;
  
  svg {
    position: absolute;
    left: 0;
    top: 2px;
    width: 40px;
    height: 40px;
    color: ${theme.colors.secondary};
  }
`