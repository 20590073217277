import React from 'react'
import PropTypes from "prop-types"
import { Title, TitleCheckedContainer } from './styles'

const TitleChecked = ({
  title
}) => (
  <h2>
    <TitleCheckedContainer>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
      <div data-aos="fade" data-aos-duration="2000">
        <Title>{title}</Title>
      </div>
    </TitleCheckedContainer>
  </h2>
)

TitleChecked.propTypes = {
  title: PropTypes.string
}

export default TitleChecked