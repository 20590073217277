import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import TitleChecked from "../components/TitleChecked"
import { PageContainer } from "../components/PageContainer/styles";
import { theme } from '../theme';

const ContactPage = () => {

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (<Layout>
    <SEO
      title="contatta Impresa Raf Service"
      description="Compila il form sottostante per ottenere un preventivo GRATUITO"
    />
    <PageContainer>
      <Container>
        <Row>
          <Col xs={12} className="mb-5">
            <p><strong>Puoi contattarci telefonicamente, via messaggio o <a href="https://wa.me/393397581130" target="_blank" className="whatsapp-link">whatsapp</a> per richiedere qualsiasi informazione</strong>:</p>
            <a href="tel:+393397581130">
              3397581130
            </a>
          </Col>
          <Col xs={12}>
            <TitleChecked title="Contatta Impresa Raf Service utilizzando il form sottostante" />
            <p className="mb-5">Puoi richiedere un preventivo totalmente <strong>GRATUITO</strong></p>
          </Col>
          <Col xs={12} sm={{ span: 6, offset: 3 }}>
            <Form action="api/form.php" method="post" onSubmit={handleSubmit} validated={validated} noValidate>
              <Form.Group className="mb-4" controlId="nome">
                <Form.Label>Nome e Cognome</Form.Label>
                <Form.Control type="text" placeholder="Inserisci il tuo nome e cognome" name="nome" required />
                <Form.Control.Feedback type="invalid">
                  Per favore inserisci il tuo nome e cognome
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4" controlId="azienda">
                <Form.Label>Azienda (lasciare vuoto se sei un privato)</Form.Label>
                <Form.Control type="text" placeholder="Inserisci il nome della tua azienda (lasciare vuoto se sei un privato)" name="azienda" />
              </Form.Group>
              <Form.Group className="mb-4" controlId="telefono">
                <Form.Label>Telefono o Cellulare</Form.Label>
                <Form.Control type="text" placeholder="Inserisci il tuo numero di telefono o cellulare" name="telefono" required />
                <Form.Control.Feedback type="invalid">
                  Per favore inserisci un tuo recapito telefonico
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Inserisci la tua email" name="email" required />
                <Form.Control.Feedback type="invalid">
                  Per favore inserisci il tuo indirizzo email
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4" controlId="messaggio">
                <Form.Label>Come possiamo esserti utile?</Form.Label>
                <Form.Control as="textarea" rows={10} placeholder="Inserisci il tuo messaggio" name="messaggio" required />
                <Form.Control.Feedback type="invalid">
                  Per favore inserisci un messaggio da inviarci
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="autorizzazione">
                <Form.Check type="checkbox" name="autorizzazione" label="Autorizzazione al trattamento dei dati personali ai sensi dell'Art. 13 del D. Lgs n. 196/2003" required />
                <Form.Control.Feedback type="invalid">
                  Per favore clicca sul quadratino per presa visione
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback>
              </Form.Group>
              <div style={{ display: 'flex', justifyContent: "center"}}>
                <Button variant="primary" type="submit" size="lg" style={{ background: theme.colors.secondary, borderColor: theme.colors.secondary }}>
                  Invia
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  </Layout>)
}

export default ContactPage
