import styled from "styled-components";
import { theme } from "../../theme";

export const PageContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  color: ${theme.colors.primary};
  min-height: calc(100vh - 253.4px);

  @media only screen and (max-width: 768px) {
    min-height: calc(100vh - 274px);
  }

  .ref-list-name {
    padding-left: 20px;
  }
`